import React, {useEffect, useRef, useState} from 'react';

import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const SearchField = ({handleSearchInputChangeDebounce}) => {
  const [isSearchWindowOpen, setSearchWindowOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const searchRef = useRef(null);
  const searchButtonRef = useRef(null);

  const handleClickSearchOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchWindowOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickSearchOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickSearchOutside);
    }
  })

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
    handleSearchInputChangeDebounce(e.target.value);
  }

  const handleOpenSearchWindow = () => {
    setSearchWindowOpen(!isSearchWindowOpen);
  };

  const SearchWindow = () => {
    const searchButton = searchButtonRef.current;

    if (searchButton) {
      const rect = searchButton.getBoundingClientRect();

      return (
        <div
          ref={searchRef}
          className='search-window'
          style={{
            top: `${rect.bottom + window.scrollY + 10}px`,
          }}
        >
          <input
            type='text'
            className='search-input'
            placeholder='Поиск задач...'
            value={searchText}
            onChange={handleSearchInputChange}
          />
          <img src={images['search.svg']} alt='search.svg'/>
        </div>
      );
    }
  };

  return (
    <>
      <div className='search-bar desktop'>
        <input
          type='text'
          className='search-input'
          placeholder='Поиск задач...'
          value={searchText}
          onChange={handleSearchInputChange}
        />
        <img src={images['search.svg']} alt='search.svg'/>
      </div>
      <button
        className='search-bar mobile'
        ref={searchButtonRef}
        onClick={handleOpenSearchWindow}
      >
        <img src={images['search.svg']} alt='search.svg'/>
      </button>
      {isSearchWindowOpen && <SearchWindow/>}
    </>
  );
};

export default SearchField;
