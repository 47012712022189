import React, {useState} from 'react';
import InputField from '@components/shared/InputField';
import ImageUtils from '@components/imageUtils';
import './EditPopup.css'; // Стили для popup

const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));

const EditChannelPopup = ({telegram, onSave, onCancel}) => {
  const [name, setName] = useState(telegram.name);
  const [link, setLink] = useState(telegram.link);

  const handleSave = () => {
    onSave({name, link});
  };

  return (
    <div className="edit-popup-overlay">
      <div className="edit-popup">
        <h2>Редактирование канала</h2>
        <InputField
          label="Название канала"
          type="text"
          placeholder="Введите новое название"
          logo={images['telegram.svg']}
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
        <InputField
          label="Ссылка на канал"
          type="text"
          placeholder="Введите новую ссылку"
          logo={images['link.svg']}
          value={link}
          handleChange={(e) => setLink(e.target.value)}
        />
        <div className="edit-popup-buttons">
          <button onClick={onCancel}>Отмена</button>
          <button onClick={handleSave}>Сохранить</button>
        </div>
      </div>
    </div>
  );
};

export default EditChannelPopup;
