import React from 'react';
import {HandySvg} from 'handy-svg';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.remainingTime === nextProps.remainingTime &&
    prevProps.lastUpdate === nextProps.lastUpdate &&
    prevProps.date === nextProps.date
  );
}

const AdditionalStatistic = ({image, textField}) => (
  <div className="additional-statistic">
    <HandySvg src={image} className="logo-15x15"/>
    <span className="text">{textField}</span>
  </div>
);

const CardFooter = React.memo(({task, isAutoTask, date, remainingTime, lastUpdate, channelId}) => (
  <div className="task-card-footer">
    <hr className="divider"/>
    <div className="additional-statistics">
      <AdditionalStatistic image={images['calendar.svg']} textField={date}/>
      <AdditionalStatistic image={images['id.svg']} textField={task.id}/>
      <AdditionalStatistic image={images['link.svg']} textField={task.target_url}/>
      {!isAutoTask && <AdditionalStatistic image={images['hourglass.svg']} textField={remainingTime}/>}
      <AdditionalStatistic image={images['todo.svg']} textField={lastUpdate}/>
      <AdditionalStatistic image={images['telegram.svg']} textField={channelId}/>
    </div>
  </div>
), areEqual);

export default CardFooter;
