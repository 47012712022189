import React, {memo} from 'react';

const ProgressBar = ({progress}) => (
  <div className="task-progress">
    <div className="task-progress-bar">
      <div
        className={`progress-line ${progress >= 100 ? 'success' : ''}`}
        style={{width: `${Math.min(100, progress)}%`}}
      ></div>
    </div>
  </div>
);

export default memo(ProgressBar);
