import React, {useRef, useState, useEffect} from 'react';

import ImageUtils from '@components/imageUtils';
import {HandySvg} from "handy-svg";

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const FilterButton = ({activeFilter, handleFilterInputChangeDebounce}) => {
  const [isFilterWindowOpen, setFilterWindowOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const filterButtonRef = useRef(null);
  const filterRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // Кнопка фильтра
  const handleClickFilterOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterWindowOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickFilterOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickFilterOutside);
    }
  })

  const FilterWindow = () => {
    const filterButton = filterButtonRef.current;

    if (filterButton) {
      const rect = filterButton.getBoundingClientRect();
      return (
        <div
          ref={filterRef}
          className='filter-window'
          style={{
            top: `${rect.bottom + window.scrollY + 10}px`,
            left: `${rect.right + window.scrollX - 190}px`,
          }}
        >
          <div
            className={`filter-item ${activeFilter === 'complite_actions' ? 'active' : ''}`}
            onClick={() => handleFilterInputChangeDebounce('complite_actions')}
          >
            По количеству
          </div>
          <div
            className={`filter-item ${activeFilter === 'active' ? 'active' : ''}`}
            onClick={() => handleFilterInputChangeDebounce('active')}
          >
            По статусу
          </div>
          <div
            className={`filter-item ${activeFilter === 'date_add' ? 'active' : ''}`}
            onClick={() => handleFilterInputChangeDebounce('date_add')}
          >
            По дате
          </div>
          <div
            className={`filter-item ${activeFilter === 'task_type' ? 'active' : ''}`}
            onClick={() => handleFilterInputChangeDebounce('task_type')}
          >
            По типу
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <button
        ref={filterButtonRef}
        onClick={() => setFilterWindowOpen(!isFilterWindowOpen)}
        className='filter-btn desktop-btn'
      >
        {windowWidth > 930 ?
          <>
            <span className='mr-5 desktop-text'>Сортировать</span>
            <img src={images['angle.svg']} alt='angle.svg'/>
          </>
          :
          <HandySvg src={images['settings.svg']} className='logo-15x15' alt='angle.svg'/>
        }
      </button>
      {isFilterWindowOpen && <FilterWindow/>}
    </>
  );
};

export default FilterButton;
