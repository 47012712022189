import React from 'react';
import {HandySvg} from 'handy-svg';
import {Tooltip} from 'react-tooltip';

import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const StatusProgress = ({status, progress, task, taskTime}) => (
  <div className="status-progress-row">
    <div className="task-status">{status}</div>
    <div className="progress-info">
      {Math.min(100, progress)}%
      {progress >= 100 ? (
        <img src={images['success.svg']} alt="logo"/>
      ) : (
        <>
          <div
            data-tooltip-id={`info-tooltip-${task.id}`}
            data-tooltip-class-name="info-tooltip"
            data-tooltip-place="top-end"
            className="ml-5"
            style={{height: '15px', display: 'flex'}}
          >
            <HandySvg src={images['info.svg']} className="logo-15x15"/>
          </div>
          <Tooltip id={`info-tooltip-${task.id}`} className="task-card-remain-time">
            На выполнение осталось {taskTime}
          </Tooltip>
        </>
      )}
    </div>
  </div>
);

export default StatusProgress;
