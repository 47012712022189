import React from 'react';
import TelegramItem from './TelegramItem';
import InputField from '@components/shared/InputField';
import ImageUtils from '@components/imageUtils';
import {deleteChannel} from '@components/shared/api';
import './ChannelsList.css'; // Подключите стили, если нужно

const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));

const ChannelsList = React.memo(({
                                   telegrams,
                                   setTelegrams,
                                   newTelegramName,
                                   newTelegramLink,
                                   handleNameChange,
                                   handleLinkChange,
                                   addChannel,
                                   handleEditClick
                                 }) => {
  const handleDeleteChannel = async (index) => {
    try {
      const response = await deleteChannel(index);
      if (response?.data?.status) {
        alert('Канал успешно удалён!');
        setTelegrams((prevTelegrams) => prevTelegrams.filter((_, i) => i !== index));
      } else {
        alert('При удалении канала произошла ошибка');
      }
    } catch (error) {
      console.error('Ошибка при удалении канала:', error);
    }
  };

  return (
    <div className='profile-settings-info-item'>
      <div className='profile-settings-input-fields'>
        <InputField
          label="Название канала"
          type="text"
          placeholder="Например Amro"
          logo={images['telegram.svg']}
          value={newTelegramName}
          handleChange={handleNameChange}
        />
        <InputField
          label="Ссылка на канал"
          type="text"
          placeholder="https://t.me/+Hp5DjFnpWXdhMTBi"
          logo={images['link.svg']}
          value={newTelegramLink}
          handleChange={handleLinkChange}
        />
      </div>
      <button className='add-channel-btn' onClick={addChannel}>Добавить канал</button>
      <div className='profile-settings-telegram-container'>
        {Array.isArray(telegrams) && telegrams.map((telegram, index) => (
          <TelegramItem
            key={telegram.link}
            telegram={telegram}
            index={index}
            handleDelete={handleDeleteChannel}
            handleEdit={() => handleEditClick(index)}
          />
        ))}
      </div>
    </div>
  );
});

export default ChannelsList;
