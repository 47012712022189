import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '@context/AuthContext';
import { ThemeContext } from '@context/ThemeContext';
import './Mobile.css';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/header', false, /\.(svg)$/));

export default function Mobile({ isHome = false }) {
  const {toggleTheme} = useContext(ThemeContext);
  const { isAuthenticated, userInfo, logout, handleLogin, handleRegistration } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLoginClick = () => {
    handleLogin();
    setMenuOpen(false);
  };

  const handleRegistrationClick = () => {
    handleRegistration();
    setMenuOpen(false);
  };

  return (
    <>
      <button className={`${isHome ? 'home-' : ''}hamburger-button`} onClick={handleMenuToggle}>
        <HandySvg src={images[`hamburger.svg`]} alt='menu' />
      </button>
      {menuOpen && (
        <div className="mobile-menu">
          <Link to={'/'} >
            <HandySvg src={images['ToUP.svg']} className="mobile-header-title" style={{ fill: 'var(--text-color)', height: '20px', width: '60px' }} />
          </Link>
          <div className="header-buttons">
            <img src={images['lightTheme.svg']} alt="theme-toggle" className="menu-theme-toggle" onClick={toggleTheme} />
            <Link to={'/wallet'} style={{ textDecoration: 'none' }}>
              <button className="header-button narrow-button">
                <img src={images['wallet.svg']} alt="wallet" className="mr-5" />
                {userInfo.balance} ₽
              </button>
            </Link>
            <button className="header-button hiding-button">
              <img src={images['notification.svg']} alt="notifications" />
            </button>
            <button className="close-button" onClick={handleMenuToggle}>
              <img src={images['close.svg']} alt="Close" />
            </button>
          </div>
          <div className="menu-refs">
            {isAuthenticated ? (
              <>
                <div className="profile-section mobile">
                  <img src={images['userProfile.svg']} alt="Profile" className="profile-icon" />
                  <span className="profile-name">{userInfo.name}</span>
                </div>
                <Link to="/profile" className="mobile-link" onClick={handleMenuToggle}>
                  <HandySvg src={images['profile.svg']} className="header-icon mr-5" />
                  Личный кабинет
                </Link>
                <Link to="/tasks" className="mobile-link" onClick={handleMenuToggle}>
                  <HandySvg src={images['clock.svg']} className="header-icon mr-5" />
                  Управление задачами
                </Link>
                <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className="mobile-link"
                   onClick={handleMenuToggle}>
                  <HandySvg src={images['support.svg']} className="header-icon mr-5" />
                  Поддержка
                </a>
                <Link to="/partnership" className="mobile-link" onClick={handleMenuToggle}>
                  <HandySvg src={images['star.svg']} className="header-icon mr-5" />
                  Партнерство
                </Link>
              </>
            ) : (
              <>
                <Link to="/" className="mobile-link" onClick={handleMenuToggle}>
                  Главная
                </Link>
                <Link to="/about" className="mobile-link" onClick={handleMenuToggle}>
                  О нас
                </Link>
                <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className="mobile-link"
                   onClick={handleMenuToggle}>
                  Поддержка
                </a>
                {/* <a href="#" className="mobile-link">Соц. Сети</a> */}
              </>
            )}
          </div>
          <div className="auth-buttons">
            {isAuthenticated ? (
              <button className="mobile-button" onClick={logout}>Выйти</button>
            ) : (
              <>
                <button className="mobile-button" onClick={handleLoginClick}>Войти</button>
                <button className="mobile-button register-button" onClick={handleRegistrationClick}>Регистрация</button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
