import React, {useState, useContext} from 'react';
import { AuthContext } from '@context/AuthContext';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import InputField from '@components/shared/InputField';
import Subscribers from './TaskTypes/Subscribers';
import Reactions from './TaskTypes/Reactions';
import Views from './TaskTypes/Views';
import TimeField from '../TimeField';
import './TaskForm.css';
import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const TaskForm = ({handleCurrentWindow, sendTasksToServer, isSending, handleClose, isEditWindow}) => {
  const { isAdmin, userInfo } = useContext(AuthContext);
  const { taskData, handleTaskDataChange } = useContext(TaskContext);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const botInfoBlock = [
    { emoji: 'subs', text: '1 подписчик' },
    { emoji: 'views', text: '1 просмотр' },
    { emoji: 'react', text: '1 реакция' },
  ];

  const task_type_labeles = {
    'views': 'Просмотры',
    'react': 'Реакции',
    'subs': 'Подписки',
  }

  const options = isAdmin && !taskData.isAutoTask
    ? [
      { label: 'Просмотры', value: 'views' },
      { label: 'Реакции', value: 'react' },
      { label: 'Подписки', value: 'subs' },
    ]
    : [
      { label: 'Просмотры', value: 'views' },
      { label: 'Реакции', value: 'react' },
    ];

  const handleTaskTypeClick = (type) => {
    handleTaskDataChange('isAutoTask', type === 'auto');
  };

  const handleSelectChange = (value) => {
    handleTaskDataChange('task_type', value);
  };

  return (
    <div className="task-form">
      <div className='label'>
        {isEditWindow ? <h2>Вы можете изменить задачу</h2> : <h2>Создайте задачу за пару кликов</h2>}

        <img src={images['close.svg']} alt='close' onClick={handleClose}/>
      </div>
      <div className='type-task-buttons-container'>
        {isEditWindow ?
          <button
            className={`type-task-button`}
          >
            Авто задача
          </button> : <>
            <button
              className={`type-task-button ${!taskData.isAutoTask ? 'active' : ''}`}
              onClick={() => handleTaskTypeClick('task')}
            >
              Задача
            </button>
        <button
          className={`type-task-button ${taskData.isAutoTask ? 'active' : ''}`}
          onClick={() => handleTaskTypeClick('auto')}
        >
          Авто задача
        </button>
          </>}
      </div>
      <div className='task-form-count-bots'>
        <div className='left-side-bots'>
          <p className='mb-0'>У вас имеется</p>
          <div className="bot-info-container">
            <HandySvg src={images['user-robot.svg']} className="bot-icon"/>
            <p className="bot-count">{userInfo.bot_count === null ? 0 : userInfo.bot_count} ботов</p>
          </div>
        </div>
        <div
          className='right-side-bots'
          onMouseEnter={() => setIsTooltipVisible(true)}
          onMouseLeave={() => setIsTooltipVisible(false)}
        >
          <HandySvg src={images['info.svg']} className="logo-15x15"/>
          {isTooltipVisible && (
            <div className='tooltip-bots-info'>
              {botInfoBlock.map((item, index) => (
                <div key={index} className="tooltip-item">
                  <HandySvg src={images['user-robot.svg']} className="logo-15x15 mr-5"/>
                  {`1 бот = `}
                  <HandySvg src={images[`${item.emoji}.svg`]} className="logo-15x15 mx-1"/>
                  {`${item.text}`}
                </div>
              ))}
              <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer"
                 className="tooltip-link t-14">Приобрести</a>
            </div>
          )}
        </div>
      </div>
      <InputField
        label="Действия"
        type="select"
        placeholder="Выберите..."
        logo={images['todo.svg']}
        value={task_type_labeles[taskData.task_type]}
        handleChange={handleSelectChange}
        options={{options}}
      />
      {taskData.task_type === 'subs' ? <Subscribers/> : taskData.task_type === 'react' ? <Reactions/> : <Views/>}
      <TimeField/>
      <div className='task-form-btn-box'>
        <button
          type="submit"
          className='task-form-submit-button'
          onClick={sendTasksToServer}
          disabled={isSending}
        >
          {isSending ? 'Отправка...' : 'Запустить задачу'}
        </button>
        <div className='advanced-setting-btn' onClick={() => handleCurrentWindow({window: 'advanced-settings'})}>
          <HandySvg src={images[`settings.svg`]} className="logo-15x15"/>
        </div>
        {(taskData.isAutoTask || taskData.task_type === 'subs') && !isEditWindow && (
          <div className='advanced-setting-btn' onClick={() => handleCurrentWindow({window: 'calendar'})}>
            <HandySvg src={images[`calendar.svg`]} className="logo-15x15"/>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskForm;
