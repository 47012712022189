import React, {useEffect, useRef} from 'react';

export const calculateExpectedCount = (task, currentTime) => {
  const getCurrentPeriodIndex = (behavior) => {
    for (let i = 0; i < behavior.length; i++) {
      if (currentTime <= behavior[i][0]) {
        return i;
      }
    }
    return behavior.length;
  };

  const sumBehaviorCountsUpToIndex = (behavior, index) => {
    let sum = 0
    for (let i = 0; i <= index; i++) {
      sum += behavior[i][1];
    }
    return sum;
  }

  const calculateExpectedChanges = (behavior, index) => {
    if (index > 0 && index < behavior.length) {
      const elapsedTime = currentTime - behavior[index - 1][0];
      const periodDuration = behavior[index][0] - behavior[index - 1][0];
      const actionChangeCount = behavior[index][1];
      return (actionChangeCount * elapsedTime) / periodDuration;
    }
    return 0;
  };

  const index = getCurrentPeriodIndex(task.behavior);
  if (index >= 0) {
    return Math.floor(
      Math.min(
        sumBehaviorCountsUpToIndex(task.behavior, index - 1) + calculateExpectedChanges(task.behavior, index),
        task.count_actions
      )
    );
  }
  if (index < 0 && currentTime < task.behavior[0][0]) {
    return 0;
  }
  return task.count_actions;
};

const ExpectedProgress = ({task, images, setIsExpectedProgressOpen}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsExpectedProgressOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsExpectedProgressOpen]);

  return (
    <div ref={popupRef} className="task-expected">
      <div className="my-row">
        <div className="time">
          {new Date().toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
        </div>
        <img src={images['loading.svg']} alt="loading" className="logo-15x15"/>
      </div>
      <div>Ожидаемое количество: {calculateExpectedCount(task, Date.now())}</div>
    </div>
  );
};

export default ExpectedProgress;
