import React, { useState, useContext, useEffect } from 'react';
import { TaskContext } from '@components/Tasks/AddTaskWindow/TaskContext';
import InputField from '../../../shared/InputField';
import ChartComponent from './ChartComponent';
import './TaskSettings.css';

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const TaskSettings = ({ handleCurrentWindow }) => {
  const { taskData, handleTaskDataChange } = useContext(TaskContext);

  const defaultChartData = () => {
    return {
      countIntervals: taskData.countIntervals,
      behavior: taskData.behavior.slice(),
      count_actions: taskData.count_actions,
      period_time: Math.floor(taskData.task_time / (taskData.countIntervals - 1) * 1000),
    };
  };

  const [chartData, setChartData] = useState(defaultChartData());

  // useEffect для изменения period_time при изменении countIntervals
  useEffect(() => {
    const newPeriodTime = Math.floor(taskData.task_time / (chartData.countIntervals - 1) * 1000);
    setChartData((prev) => ({
      ...prev,
      period_time: newPeriodTime,
    }));
  }, [chartData.countIntervals, taskData.task_time]);

  const handleBack = () => handleCurrentWindow({ window: 'default' });

  const resetChartData = () => {
    setChartData(defaultChartData());
  };

  const saveChartData = () => {
    handleTaskDataChange('countIntervals', chartData.countIntervals);
    handleTaskDataChange('count_actions', chartData.count_actions);
    handleTaskDataChange('behavior', chartData.behavior);
    handleBack();
  };

  const handleChartData = (field, value) => {
    setChartData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const updateCountIntervals = (e) => {
    const newLength = parseInt(e.target.value, 10);

    setChartData((prev) => {
      const updatedBehavior = [...prev.behavior];

      if (newLength > updatedBehavior.length) {
        for (let i = updatedBehavior.length; i < newLength; i++) {
          updatedBehavior.push(0);
        }
      } else if (newLength < updatedBehavior.length) {
        updatedBehavior.length = newLength;
      }

      return {
        ...prev,
        behavior: updatedBehavior,
        countIntervals: newLength,
      };
    });
  };

  const HeaderTitle = () => (
    <>
      <div className='task-window-back-btn' onClick={handleBack}>
        <HandySvg src={images['back.svg']} className="logo-15x15" />
      </div>
      <div className="task-settings-header">
        <h3>Продвинутые настройки задачи</h3>
      </div>
    </>
  );

  const InputFields = () => (
    <div className="task-settings-fields">
      <InputField
        label="Интервал"
        type="number"
        placeholder="Введите количество интервалов"
        logo={images['todo.svg']}
        error={taskData.errors.interval}
        value={chartData.countIntervals}
        handleChange={updateCountIntervals}
        options={{minValue: 3, maxValue: 24}}
      />
    </div>
  );

  return (
    <div className="task-settings">
      {HeaderTitle()}
      {InputFields()}
      <div className='chart-content'>
        <ChartComponent chartData={chartData} handleChartData={handleChartData} />
      </div>
      <div className="task-settings-footer">
        <button className="reset-button" onClick={resetChartData}>Сбросить настройки</button>
        <button className="save-button" onClick={saveChartData}>Сохранить изменения</button>
      </div>
    </div>
  );
};

export default TaskSettings;
