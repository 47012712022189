import React from 'react';
import SearchField from './SearchField';
import FilterButton from "@components/Tasks/TasksPageComponents/PageElements/FilterButton";
import './TasksTitle.css';

import {HandySvg} from 'handy-svg';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const TasksTitle = ({
                      isAutoTask,
                      changeTaskType,
                      handleOpenTaskWindow,
                      handleSearchInputChangeDebounce,
                      activeFilter,
                      handleFilterInputChangeDebounce,
                    }) => {
  return (
    <div className="tasks-title">
      <div>
        <h1>{`Все ${isAutoTask ? 'авто' : ''}задачи`}</h1>
        <div style={{display: 'flex', gap: '10px'}}>
          <button
            className={`tasks-type ${isAutoTask ? '' : 'active'}`}
            onClick={() => changeTaskType('task')}
          >
            Задачи
          </button>
          <button
            className={`tasks-type ${isAutoTask ? 'active' : ''}`}
            onClick={() => changeTaskType('autotask')}
          >
            Автозадачи
          </button>
        </div>
      </div>
      <div className="tasks-buttons">
        <SearchField handleSearchInputChangeDebounce={handleSearchInputChangeDebounce}/>
        <FilterButton activeFilter={activeFilter} handleFilterInputChangeDebounce={handleFilterInputChangeDebounce}/>
        <button onClick={handleOpenTaskWindow} className="square-box">
          <HandySvg src={images['add.svg']} className="logo-15x15"/>
        </button>
      </div>
    </div>
  );
};

export default TasksTitle;
