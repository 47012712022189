import React from 'react';
import InputField from '@components/shared/InputField';
import ImageUtils from '@components/imageUtils';
import './PasswordReset.css'; // Если нужны отдельные стили

const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));

const PasswordReset = ({
                         password,
                         confirmPassword,
                         handlePasswordChange,
                         handleConfirmPasswordChange,
                         resetPassword
                       }) => (
  <div className='profile-settings-info-item'>
    <div className='profile-settings-input-fields'>
      <InputField
        label="Пароль"
        type="password"
        placeholder="Введите пароль"
        logo={images['password.svg']}
        value={password}
        handleChange={handlePasswordChange}
      />
      <InputField
        label="Повторите пароль"
        type="password"
        placeholder="Повторите пароль"
        logo={images['password.svg']}
        value={confirmPassword}
        handleChange={handleConfirmPasswordChange}
      />
    </div>
    <button className='add-channel-btn' onClick={resetPassword}>Изменить пароль</button>
  </div>
);

export default PasswordReset;
