import React, {useState, useEffect, useCallback} from 'react';
import CardHeader from "@components/Tasks/TasksPageComponents/TaskCard/Header/CardHeader";
import CardBody from "@components/Tasks/TasksPageComponents/TaskCard/Body/CardBody";
import CardFooter from "@components/Tasks/TasksPageComponents/TaskCard/Footer/CardFooter";
import './TaskCard.css';

const TaskStatus = (progress, isActive) => {
  return progress >= 100 ? 'Завершено' : isActive ? 'В прогрессе' : 'На паузе'
}

const TaskCard = ({task, isAutoTask}) => {
  const [taskTime, setTaskTime] = useState('');
  const [progress, setProgress] = useState(0);
  const [isActive, setIsActive] = useState(task.active);
  const [status, setStatus] = useState(TaskStatus(progress, isActive));
  const [date, setDate] = useState('');
  const [isDeleted, setIsDeleted] = useState(task.is_remove);


  const getTaskTime = (time) => {
    const msPerMinute = 60;
    const msPerHour = 60 * msPerMinute;
    const msPerDay = 24 * msPerHour;
    const msPerWeek = 7 * msPerDay;

    const weeks = Math.floor(time / msPerWeek);
    const days = Math.floor((time % msPerWeek) / msPerDay);
    const hours = Math.floor((time % msPerDay) / msPerHour);
    const minutes = Math.floor((time % msPerHour) / msPerMinute);

    if (weeks > 0) {
      return `${weeks} ${weeks === 1 ? 'неделя' : 'недели'}`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? 'день' : 'дня'}`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? 'час' : 'часов'}`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'минута' : 'минут'}`;
    } else {
      return "менее минуты";
    }
  };

  const getDate = useCallback(() => {
    const date = new Date(task.date_add * 1000);
    const months = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${day} ${month}, ${year}`;
  }, [task.date_add]); // Зависимость от task.date_add

  useEffect(() => {
    setProgress(Math.floor(task.complite_actions / task.count_actions * 100));
    setStatus(TaskStatus(progress, isActive));
    setDate(getDate());
    setTaskTime(getTaskTime(task.task_time));
  }, [task.complite_actions, task.count_actions, task.task_time, isActive, progress, getDate]);



  const getRemainingTime = (time) => {
    const msPerMinute = 60;
    const msPerHour = 60 * msPerMinute;
    const msPerDay = 24 * msPerHour;
    const msPerWeek = 7 * msPerDay;

    const weeks = Math.floor(time / msPerWeek);
    const days = Math.floor((time % msPerWeek) / msPerDay);
    const hours = Math.floor((time % msPerDay) / msPerHour);
    const minutes = Math.floor((time % msPerHour) / msPerMinute);

    if (weeks > 0) {
      return `${weeks} нед. ${days} дн.`;
    } else if (days > 0) {
      return `${days} дн. ${hours} ч.`;
    } else if (hours > 0) {
      return `${hours} ч. ${minutes} м.`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'минута' : 'минут'}`;
    } else {
      return "0 минут";
    }
  };

  const getLastUpdate = () => {
    const date = new Date(task.date_add * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Добавляем ведущий 0 для минут
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const extractChannelId = (url) => {
    const regex = /t\.me\/(?:c\/)?([^/]+)\/\d+/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  return (
    !isDeleted &&
    <div className={`task-card ${!isActive ? 'unactive' : ''}`}>
      <CardHeader
        task={task}
        isAutoTask={isAutoTask}
        taskTime={taskTime}
        progress={progress}
        isActive={isActive}
        setIsActive={setIsActive}
        setIsDeleted={setIsDeleted}
      />
      {!isAutoTask && <CardBody
        task={task}
        progress={progress}
        isActive={isActive}
        status={status}
        taskTime={getTaskTime((task.date_add + task.task_time) - parseInt(Date.now() / 1000))}
      />}
      <CardFooter
        task={task}
        isAutoTask={isAutoTask}
        date={date}
        remainingTime={getRemainingTime((task.date_add + task.task_time) - parseInt(Date.now() / 1000))}
        lastUpdate={getLastUpdate()}
        channelId={task.channel_id ? task.channel_id : extractChannelId(task.target_url)}
      />
    </div >
  );
}

export default TaskCard;
