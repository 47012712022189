import React from 'react';
import ExpectedProgress from './ExpectedProgress';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.isExceptedProgressOpen === nextProps.isExceptedProgressOpen &&
    nextProps.isExceptedProgressOpen === false &&
    prevProps.setIsExpectedProgressOpen === nextProps.setIsExpectedProgressOpen &&
    prevProps.task.complite_actions === nextProps.task.complite_actions &&
    prevProps.isActive === nextProps.isActive
  );
}

const TaskStatistic = React.memo(({task, isActive, isExceptedProgressOpen, setIsExpectedProgressOpen}) => {

  return <div className="task-statistics">
    <div>
      {Math.min(task.count_actions, task.complite_actions)} из {task.count_actions}
    </div>
    {task.complite_actions < task.count_actions && isActive ?
      <div onClick={() => setIsExpectedProgressOpen(true)} className="show-progress">
        Показать прогресс
      </div> : ''
    }
    {isExceptedProgressOpen &&
      <ExpectedProgress
        task={task}
        images={images}
        setIsExpectedProgressOpen={setIsExpectedProgressOpen}
      />
    }
  </div>
}, areEqual);

export default TaskStatistic;
