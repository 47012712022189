import React, {useState, useCallback, useMemo} from 'react';
import {HandySvg} from 'handy-svg';
import TelegramPost from './TelegramPost';
import EditMenu from './EditMenu';
import ImageUtils from '@components/imageUtils';
import {editTask} from "@components/shared/api";

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.taskTime === nextProps.taskTime &&
    prevProps.progress === nextProps.progress &&
    prevProps.isActive === nextProps.isActive &&
    prevProps.setIsActive === nextProps.setIsActive &&
    prevProps.setIsDeleted === nextProps.setIsDeleted
  );
};

const CardHeader = React.memo(({
                                 task,
                                 isAutoTask,
                                 taskTime,
                                 progress,
                                 isActive,
                                 setIsActive,
                                 setIsDeleted,
                               }) => {
  const [showPost, setShowPost] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  // Мемоизация объекта с подписями
  const typeLabel = useMemo(() => ({
    'subs': 'Подписчиков',
    'react': 'Реакций',
    'views': 'Просмотров',
  }), []);

  const postId = useMemo(() => {
    const regex = /t\.me\/(?:c\/)?[^/]+\/(\d+)/;
    const match = task.target_url.match(regex);
    return match ? match[1] : null;
  }, [task.target_url]);

  const handleEditToggle = useCallback(() => {
    setIsEditOpen((prev) => !prev);
  }, []);

  const handleStopTask = useCallback(async () => {
    const response = await editTask(
      isAutoTask,
      task.id,
      'active',
      Math.abs(task.active - 1),
    );

    if (response.data.status) {
      setIsActive(!isActive);
    }
  }, [isAutoTask, task.id, task.active, setIsActive, isActive]);

  const handleDeleteTask = useCallback(async () => {
    const response = await editTask(
      isAutoTask,
      task.id,
      'is_remove',
      1,
    );

    if (response.data.status) {
      setIsDeleted(true);
    }
  }, [isAutoTask, task.id, setIsDeleted]);

  return (
    <div className="task-card-header">
      <div className="upper">
        <HandySvg
          src={images[`menuDots.svg`]}
          className="logo-15x15 edit-menu-btn"
          onClick={handleEditToggle}
        />
        {isEditOpen && (
          <EditMenu
            task={task}
            isAutoTask={isAutoTask}
            progress={progress}
            isActive={isActive}
            onStopTask={handleStopTask}
            onDeleteTask={handleDeleteTask}
            setIsEditOpen={setIsEditOpen}
          />
        )}
        <div className="task-due-date">
          <img src={images[`calendarClock.svg`]} alt="logo"/>
          {taskTime}
        </div>
      </div>
      <div className="task-title">
        <div className="task-type-logo">
          <HandySvg src={images[`${task.task_type}.svg`]} className="logo-20x20 currentColor"/>
        </div>
        {task.count_actions}
        <br/>
        {typeLabel[task.task_type]}
      </div>
      <div className="task-id" onClick={() => setShowPost(true)}>
        <HandySvg src={images[`note.svg`]} className="logo-15x15 currentColor"/>
        {postId}
        <HandySvg src={images[`info.svg`]} className="logo-15x15"/>
      </div>
      {showPost && !task.isAutoTask && task.task_type !== 'subs' && (
        <TelegramPost post={task.post_info_tg} setShowPost={setShowPost}/>
      )}
    </div>
  );
}, areEqual);

export default CardHeader;
