import React from 'react';
import './ProfileInfoSection.css';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/profile', false, /\.(svg)$/));

// Оптимизированный DetailItem
const DetailItem = React.memo(({title, description, logo}) => (
  <div className="detail-item">
    <p>
      <img
        src={images[`${logo}.svg`]}
        alt="logo"
        draggable="false" // Запрещаем перетаскивание
      />
      {title}
    </p>
    <span className="t-14">{description}</span>
  </div>
));

const ProfileInfoSection = React.memo(() => (
  <section>
      <div className="subscription-text">
        <div className="banner-label">Горячее предложение</div>
        <h2>Приобретите подписку по выгодной цене</h2>
        <div className='description-block'>
        <div>Что вы получите:</div>
        <div className="subscription-details">
          <DetailItem
            title="5 000 ботов"
            description="Для просмотра, реакций и подписчиков"
            logo="bots"
          />
          <DetailItem
            title="10 каналов"
            description="Подписка действует до 10 каналов телеграм"
            logo="channels"
          />
        </div>
        <a
          href="https://t.me/toupSupport_bot"
          target="_blank"
          rel="noopener noreferrer"
          className="subscription-button"
        >
          Приобрести сейчас
        </a>
        </div>
      </div>
      <img
        src={images['chuvachki.svg']}
        alt="logo"
        className="chuvachki"
        draggable="false" // Запрещаем перетаскивание
      />
  </section>
));

export default ProfileInfoSection;
