import React, {useState} from 'react';
import StatusProgress from './StatusProgress';
import ProgressBar from './ProgressBar';
import TaskStatistic from './TaskStatistic';

const CardBody = ({
                    task,
                    progress,
                    isActive,
                    status,
                    taskTime,
                  }) => {
  const [isExceptedProgressOpen, setIsExpectedProgressOpen] = useState(false);

  return (
    <div className="task-card-body">
      <StatusProgress
        status={status}
        progress={progress}
        task={task}
        taskTime={taskTime}
      />
      <ProgressBar progress={progress}/>
      <TaskStatistic
        task={task}
        isActive={isActive}
        isExceptedProgressOpen={isExceptedProgressOpen}
        setIsExpectedProgressOpen={setIsExpectedProgressOpen}
      />
    </div>
  );
};

// Обернём компонент в React.memo для предотвращения лишних рендеров
export default CardBody;
