import React from 'react';
// import './LoadingScreen.css'; // Добавьте стили для компонента, если необходимо

const LoadingScreen = () => (
  <div className="loading-screen">
    <div className="spinner"></div>
  </div>
);

export default LoadingScreen;
