import React, {useEffect, useRef, useState} from 'react';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));


const TelegramItem = React.memo(({telegram, index, handleDelete, handleEdit}) => {
  const [isMobileWindowOpen, setIsMobileWindowOpen] = useState(false);
  const editButtonRef = useRef(null);
  const editWindowRef = useRef(null);

  // Кнопка фильтра
  const handleClickEditOutside = (event) => {
    if (editWindowRef.current && !editWindowRef.current.contains(event.target)) {
      setIsMobileWindowOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickEditOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickEditOutside);
    }
  })

  const MobileWindow = () => {
    const editButton = editButtonRef.current;

    if (editButton) {
      const rect = editButton.getBoundingClientRect();
      return (
        <div className='telegram-settings-mobile'
             ref={editWindowRef}
             style={{
               top: `${rect.top + window.scrollY - 110}px`,
               left: `${rect.left + window.scrollX - 5}px`,
             }}
        >
          <button className='edit-btn mobile' onClick={() => handleEdit(index)}>
            <img src={images['edit.svg']} alt="Edit Icon"/>
          </button>
          <button className='edit-btn mobile' onClick={() => handleDelete(index)}>
            <img src={images['delete.svg']} alt="Delete Icon"/>
          </button>
        </div>
      )
    }
    return <></>
  }

  return (
    <div className='profile-settings-telegram-item'>
      <div className='telegram-name'>
        <img src={images['telegram.svg']} alt="Telegram Logo"/>
        <span>{telegram.name}</span>
      </div>
      <div className='telegram-link'>
        <img src={images['link.svg']} alt="Link Icon"/>
        <span>{telegram.link}</span>
      </div>
      <button className='edit-btn desktop' onClick={() => handleEdit(index)}>
        <img src={images['edit.svg']} alt="Edit Icon"/>
      </button>
      <button className='edit-btn desktop' onClick={() => handleDelete(index)}>
        <img src={images['delete.svg']} alt="Delete Icon"/>
      </button>
      <button className='edit-btn mobile' ref={editButtonRef}
              onClick={() => setIsMobileWindowOpen(!isMobileWindowOpen)}>
        <img src={images['3points.svg']} alt="More Options Icon"/>
      </button>
      {isMobileWindowOpen && (<MobileWindow/>)}
    </div>
  )
});

export default TelegramItem;
