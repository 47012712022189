import React, {useEffect, useContext, useState, useRef} from "react";
import {AuthContext} from "@context/AuthContext";
import RegistrationForm from "./RegistrationForm";
import RegistrationInfoBlock from "./RegistrationInfoBlock";
import {fetchRegistration} from "@components/shared/api";
import "./Registration.css";

const Registration = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [channel, setChannel] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [tempToken, setTempToken] = useState('123123');

  const {handleClose, handleLogin} = useContext(AuthContext);
  const [stageStep, setStageStep] = useState("Registration");
  const [code, setCode] = useState(["", "", "", ""]); // Хранение кода
  const [ptr, setPtr] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!e.target.closest(".auth-window")) {
        handleClose();
      }
    };

    const handleKeyPress = (e) => {
      if (stageStep === "Code" && /^[0-9]$/.test(e.key)) {
        const newCode = [...code];

        if (ptr < code.length) {
          newCode[ptr] = e.key;
          setCode(newCode);
          setPtr((prevPtr) => prevPtr + 1);
        }
      }

      if (stageStep === "Code" && e.key === "Backspace") {
        const newCode = [...code];

        if (ptr > 0) {
          newCode[ptr - 1] = "";
          setCode(newCode);
          setPtr((prevPtr) => prevPtr - 1);
        }
      }
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleClose, stageStep, code, ptr]);

  const handleCodeStep = () => {
    setStageStep("Code");
  };

  const handleRegistration = async () => {
    setError(null);
    if (password !== confirmPassword) {
      setError("Пароли не совпадают");
      return;
    }

    setIsSending(true);
    try {
      const codeString = code.join("");
      const response = await fetchRegistration(email, password, name, channel, tempToken, codeString);
      if (response.data?.status) {
        handleLogin();
      } else {
        setError(response?.data?.message || "Не удалось зарегистрироваться")
      }
    } catch (err) {
      console.error("Ошибка регистрации:", err);
      setError(err.response?.data?.message || "Не удалось зарегистрироваться");
    } finally {
      setIsSending(false);
    }
  };

  const CodeStage = () => {
    return (
      <div className="auth-form" style={{gap: "5px"}}>
        <p className="text-blue-left">Подтвердите код</p>
        <h2>Мы отправили код на вашу почту</h2>
        <div className="code-field-container" ref={containerRef}>
          {code.map((char, index) => (
            <div key={index} className={`code-field-item${ptr === index ? ' active' : ''}`}>
              {char || "_"}
            </div>
          ))}
        </div>
        <span className="text-blue-left">
          Не пришел код?
          <span style={{color: "var(--text-color)"}}>
            examplelongpost@gmail.com
          </span>
        </span>
        <button onClick={handleRegistration} disabled={isSending}>
          {isSending ? "Отправка..." : "Отправить код"}
        </button>
        <button
          className="reset-back-btn"
          onClick={() => setStageStep("Registration")}
        >
          Назад
        </button>
        {error && <p className="error-message">{error}</p>}
      </div>
    );
  };

  return (
    <div className="auth-window-overlay">
      <div className="auth-window">
        <div className="auth-content">
          {stageStep === "Registration" ? (
            <RegistrationForm
              handleCodeStep={handleCodeStep}
              email={email} setEmail={setEmail}
              name={name} setName={setName}
              channel={channel} setChannel={setChannel}
              password={password} setPassword={setPassword}
              confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword}
              error={error} setError={setError}
              temp_token={tempToken}
            />
          ) : (
            <CodeStage/>
          )}
          <RegistrationInfoBlock/>
        </div>
      </div>
    </div>
  );
};

export default Registration;