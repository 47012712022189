import React from 'react';
import InputField from '@components/shared/InputField';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/profile/options', false, /\.(svg)$/));

const UserInfo = React.memo(({username, email, handleUsernameChange, handleEmailChange, saveButton}) => {
  return (
    <div className='profile-settings-info-item'>
      <div className='profile-settings-input-fields'>
        <InputField
          label="Имя"
          type="text"
          placeholder="Ваше имя"
          logo={images['user.svg']}
          value={username}
          handleChange={handleUsernameChange}
        />
        <InputField
          label="Email"
          type="email"
          placeholder="Ваша почта"
          logo={images['mail.svg']}
          value={email}
          handleChange={handleEmailChange}
        />
      </div>
      <button className='add-channel-btn' onClick={saveButton}>Сохранить изменения</button>
    </div>
  );
});

export default UserInfo;
