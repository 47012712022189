import React, {useContext} from 'react';
import { AuthContext } from '@context/AuthContext';
import './Profile.css';
import BannerSlider from "@components/Profile/Banners/BannerSlider";
import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import ProfileCard from './ProfileCard';

const ProfileTitle = () => {
  const { userInfo } = useContext(AuthContext);

  return (
    <header className="profile-header">
      <h1>{`Добро пожаловать, ${userInfo.name}`}</h1>
    </header>
  )
};

const CardSection = () => (
  <section className="cards-section">
    <ProfileCard
      title="Настройки профиля"
      text="Перейти"
      icon="profileSetting"
      link="/settings"
    />
    <ProfileCard
      title="Управление задачами"
      text="Начать работу"
      icon="taskManage"
      link="/tasks"
    />
    <ProfileCard
      title="Кошелек"
      text="Перейти"
      icon="wallet"
      link="/wallet"
    />
  </section>
);

const Profile = () => {
  return (
    <>
      <Header />
      <div className="profile-container">
        <ProfileTitle />
        <BannerSlider/>
        <CardSection />
      </div>
      <Footer />
    </>
  );
};

export default Profile;
