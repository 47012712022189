import React, {useEffect, useState} from 'react';
import {HandySvg} from 'handy-svg';
import ImageUtils from '@components/imageUtils';
import './Pagination.css'

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const CountPerPage = ({taskPerPage, handleCountItems}) => {
  return (
    <div className="task-count-container">
      <select value={taskPerPage} onChange={handleCountItems}>
        <option value={15}>15 задач</option>
        <option value={25}>25 задач</option>
        <option value={50}>50 задач</option>
        <option value={100}>100 задач</option>
      </select>
    </div>
  )
}

const PaginationPages = ({activePage, countPages, onPageChange}) => {
  const [startPage, setStartPage] = useState(2);
  const [endPage, setEndPage] = useState(5);
  const [pages, setPages] = useState([]);

  const handlePageNumber = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const TaskPage = ({pageNumber}) => (
    <button
      key={pageNumber}
      className={`page ${activePage === pageNumber ? 'active' : ''}`}
      onClick={() => handlePageNumber(pageNumber)}
    >
      {pageNumber}
    </button>
  );

  useEffect(() => {
    if (activePage < 5) {
      setStartPage(Math.min(2, countPages));
      setEndPage(Math.min(countPages - 1, 5));
    } else if (activePage > countPages - 3) {
      setStartPage(Math.max(2, countPages - 3));
      setEndPage(Math.min(countPages - 1, 5));
    } else {
      setStartPage(Math.min(2, countPages - 1));
    }
    setStartPage(Math.max(2, activePage - 1));
    setEndPage(Math.min(countPages - 1, activePage + 1));
  }, [activePage, countPages]);

  useEffect(() => {
    const newPages = [];
    for (let i = startPage; i <= endPage; i++) {
      newPages.push(<TaskPage key={i} pageNumber={i}/>);
    }
    setPages(newPages);
  }, [startPage, endPage]);

  const PrevPageArrow = () => (
    <button
      className={`page ${activePage > 1 ? 'active' : ''}`}
      onClick={() => handlePageNumber(activePage > 1 ? activePage - 1 : 1)}
    >
      <HandySvg src={images['prevPage.svg']} alt="prevPage.svg" className="currentColor logo-15x15"/>
    </button>
  )

  const NextPageArrow = () => (
    <button
      className={`page ${activePage < countPages ? 'active' : ''}`}
      onClick={() => handlePageNumber(activePage < countPages ? activePage + 1 : countPages)}
    >
      <HandySvg src={images['nextPage.svg']} alt="nextPage.svg" className="currentColor logo-15x15"/>
    </button>
  )

  const TripplePoints = () => (
    <span className="triple-points">...</span>
  )

  return (
    <div className="pages-container">
      <PrevPageArrow/>
      <TaskPage pageNumber={1}/>
      {startPage >= 3 && <TripplePoints/>}
      {pages}
      {endPage <= countPages - 2 && <TripplePoints/>}
      {countPages > 1 && <TaskPage pageNumber={countPages}/>}
      <NextPageArrow/>
    </div>
  )
}

const Pagination = ({activePage, countPages, taskPerPage, onPageChange, onItemsPerPageChange}) => {
  const handleCountItems = (event) => {
    const countItems = parseInt(event.target.value);
    onItemsPerPageChange(countItems);
  };

  return (
    <div className="tasks-pagination">
      <CountPerPage taskPerPage={taskPerPage} handleCountItems={handleCountItems}/>
      <PaginationPages activePage={activePage} countPages={countPages} onPageChange={onPageChange}/>
    </div>
  );
};

export default Pagination;
