import React, {createContext, useState, useEffect, useCallback} from 'react';
import {fetchUserInfo} from '@components/shared/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [activeAuthMenu, setActiveAuthMenu] = useState('');

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    setUserInfo({});
    setIsAdmin(false);
  });

  const handleChangeUserInfo = (updates) => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      ...updates, // Обновление нескольких полей
    }));
  };

  const handleFetchUserInfo = useCallback(
    async (token) => {
      if (!token) {
        logout();
        return;
      }
      const response = await fetchUserInfo(token); // Передача токена в API
      if (response.data?.status) {
        setUserInfo(response.data);
        if (response.data.permission) {
          setIsAdmin(response.data.permission === 'admin');
        }
      } else {
        logout();
      }
    },
    [logout]
  );

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAuthenticated(true);
        await handleFetchUserInfo(token);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const login = () => {
    setIsAuthenticated(true);
    const token = localStorage.getItem('token');
    handleFetchUserInfo(token);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleRegistration = () => {
    setActiveAuthMenu('Registration');
  };

  const handleLogin = () => {
    setActiveAuthMenu('Login');
  };

  const handleReset = () => {
    setActiveAuthMenu('Reset');
  };

  const handleClose = () => {
    setActiveAuthMenu('');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        userInfo,
        handleChangeUserInfo,
        login,
        logout,
        activeAuthMenu,
        handleRegistration,
        handleLogin,
        handleReset,
        handleClose,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
