import React from 'react';
// import './EmptyPage.css'; // Добавьте стили для компонента, если необходимо

import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const EmptyPage = ({onCreateTask}) => (
  <div className="empty-tasks">
    <img src={images['emptyTasks.svg']} alt="emptyTasks.svg"/>
    <div className="label">Список пуст</div>
    <div className="description">У вас пока не создано ни одной задачи</div>
    <button onClick={onCreateTask}>Создать</button>
  </div>
);

export default EmptyPage;
